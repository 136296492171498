import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { ApiData, Guid } from 'domain/types';
import { SureTaxCode, ExemptionCode } from 'domain/entities';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class SureTaxCodeRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getExemptionCode$(): Observable<ExemptionCode[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/sure-tax-exemption-codes`)
			.pipe(
				map(res => res.body.data.map(w => new ExemptionCode().deserialize(w))));
	}

	async getExemptionCodes(destroy$?: Subject<void>): Promise<ExemptionCode[]> {
		const res = await this.http.promise(destroy$).get<ApiData<ExemptionCode[]>>(`${this.config.apiUrl}${this.config.apiVersion}/sure-tax-exemption-codes`);

		return res.body?.data?.map(x => new ExemptionCode().deserialize(x));
	}

	getSureTaxCodes$(): Observable<SureTaxCode[]> {
		const key = 'suretax-codes';

		if (this.cacheHasKey(key)) {
			return this.getCache<SureTaxCode[]>(key);
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/sure-tax-codes`)
				.pipe(
					map(res => {
						const sureTaxCode = res.body.data.map(x => new SureTaxCode().deserialize(x));
						this.setCacheValue(key, sureTaxCode, null);
						return sureTaxCode;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	async getSureTaxCodes(destroy$?: Subject<void>): Promise<SureTaxCode[]> {
		const res = await this.http.promise(destroy$).get<ApiData<SureTaxCode[]>>(`${this.config.apiUrl}${this.config.apiVersion}/sure-tax-codes`);
		return res.body.data.map(x => new SureTaxCode().deserialize(x));
	}

	public addSureTaxCode(code: SureTaxCode): Observable<Guid> {

		const key = 'suretax-codes';

		return this.createEntity(`/sure-tax-code`, code)
			.pipe(
				tap(s => this.addCacheCollectionItem(key, s))
				, map(s => s.id));
	}

	public deleteSureTaxCode(ids: Guid[]): Observable<HttpResponse<void>> {

		const key = 'suretax-codes';

		const observable =
			this.deleteEntities(`/sure-tax-codes/delete`, ids)
				.pipe(
					tap(() => this.removeManyFromCacheCollection(key, ids)));

		return observable;
	}
}
