<section class="scrollable__wrapper">
    <div #scrollableContainer class="scrollable__container">
      <ng-content></ng-content>
    </div>
    <button
        *ngIf="isOverflow && showArrows"
        type="button"
        class="scrollable__button"
        id="scroll-left"
        [class.disable]="!canScrollStart"
        (click)="scroll(-1)"
    >
     <i class="fa fa-chevron-left" aria-hidden="true"></i>
    </button>

    <button
        *ngIf="isOverflow && showArrows"
        type="button"
        class="scrollable__button"
        id="scroll-right"
        [class.disable]="!canScrollEnd"
        (click)="scroll(1)"
    >
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
    </button>
</section>

