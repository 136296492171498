import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';

  
export interface LabelCellParams<T> { 
	label?: string;
	onClick: (node: IRowNode<T>) => void | Promise<void>; 
}

type LabelParams<T> = ICellRendererParams<T> & LabelCellParams<T>;

@Component({
	selector: 'dg-label-cell-renderer',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<button type="button" class="users__row-action" (click)="onActionClick($event, params)">
		{{params.label}}
	</button>`
})
export class LabelCellRendererComponent<T> implements ICellRendererAngularComp {
	params: LabelParams<T>;

	constructor(private readonly _cdr: ChangeDetectorRef) {}

	agInit(params: LabelParams<T>): void {
		this.params = params;
		this._cdr.markForCheck();
	}

	refresh(params: LabelParams<T>): boolean {
		return false;
	}

	onActionClick(event: Event, input: LabelCellParams<T>): void {
		event.stopPropagation();

		if (input.onClick instanceof Function) {
			void input.onClick(this.params.node);
		}

		this._cdr.markForCheck();
	}
}