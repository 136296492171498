import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LangDefinition, TranslocoService } from '@ngneat/transloco';
import { AuthService } from 'core/auth';
import { NgxPermissionsService } from 'ngx-permissions';
import { VersionRepository } from 'repositories';
import { CacheService } from 'services/cache.service';
import { MyDetailsRepository } from 'repositories/my-details-repository';
import { Subject, takeUntil } from 'rxjs';
import * as semver from 'semver';
import { Breadcrumb, DgCrumbsService } from 'shared/components/dg-crumbs';
import { AppConfig } from 'core/app-config';
import { GenericEvent } from 'domain/types';
import { Globals } from 'tools/globals';
import { VersionProvider } from 'tools/version-provider';
import { permissions } from 'tools/permissions';

@Component({
	selector: 'dg-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.sass']
})

export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('headerContent') headerContent: ElementRef;
	shouldStick = false;
	displayName: string;
	latestVersion: string;
	currentVersion: string;
	headerContentTop: number;
	timerHandle: NodeJS.Timer;
	isCrumbDisabled = true;
	availableLangs: LangDefinition[];
	activeLang: string;
	private destroy$ = new Subject<void>();
	protected readonly permissions = permissions;

	constructor(
		public transloco: TranslocoService,
		private readonly route: ActivatedRoute,
		private readonly authService: AuthService,
		private readonly globals: Globals,
		private readonly router: Router,
		private readonly myDetailsRepository: MyDetailsRepository,
		private readonly versionRepository: VersionRepository,
		private readonly cacheService: CacheService,
		private readonly titleService: Title,
		private readonly dgCrumbsService: DgCrumbsService,
		private readonly permissionsService: NgxPermissionsService,
		private readonly config: AppConfig
	) {
		this.displayName = config.myDetails.displayName;
		const REFRESH_INTERVAL = 33500;
		this.currentVersion = VersionProvider.version.toString();

		this.availableLangs = <LangDefinition[]>transloco.getAvailableLangs();
		const activeLangId = transloco.getActiveLang();
		this.activeLang = this.availableLangs.find(l => l.id === activeLangId).label;

		this.timerHandle = setInterval(() => this.getLatestVersion(), REFRESH_INTERVAL);
		this.getLatestVersion();

		myDetailsRepository.myDetails$?.pipe(takeUntil(this.destroy$)).subscribe(me => {
			if (me) {
				this.displayName = me.displayName;
			}
		});

		this.dgCrumbsService.breadcrumbChanged.pipe(takeUntil(this.destroy$)).subscribe((crumbs) => {
			const isCustomerRoute = this.router.url.indexOf('/customers/' + this.globals.customerId) > -1;
			const isSettingsRoute = this.router.url.indexOf('/settings/') > -1;
			const isQuoterExternalQuotes = this.router.url.indexOf('/external-quotes/quoter/') > -1;
			const isItQuoterExternalQuotes = this.router.url.indexOf('/external-quotes/it-quoter/') > -1;

			if (isCustomerRoute || isSettingsRoute) {
				this.isCrumbDisabled = false;
			} else {
				this.isCrumbDisabled = true;
			}

			// provide breadcrumb link on details page if we on customer
			if (isCustomerRoute) {
				crumbs[1].url = '/customers/' + this.globals.customerId + '/details';
			}

			// prevent to navigate to blank page
			if (isQuoterExternalQuotes) {
				crumbs[0].url = '/external-quotes/quoter/quotes/';
			}

			// prevent to navigate to blank page
			if (isItQuoterExternalQuotes) {
				crumbs[0].url = '/external-quotes/it-quoter/quotes/';
			}

			setTimeout(() => {
				// Allow nested compenents init their globals
				this.titleService.setTitle(this.createTitle(crumbs));
			});
		});
	}

	ngOnInit(): void {

	}

	ngAfterViewInit(): void {
		this.calculateHeaderTop();
		this.bingScrollEvent();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private calculateHeaderTop(): void {
		this.headerContentTop = this.headerContent.nativeElement.getBoundingClientRect().top;
	}

	private getLatestVersion(): void {
		this.versionRepository.getLatestVersion()?.pipe(takeUntil(this.destroy$)).subscribe(r =>
			this.latestVersion = r);
	}

	public get versionIsOld(): boolean {
		if (this.currentVersion && this.latestVersion) {
			return semver.gt(this.latestVersion, this.currentVersion);
		} else {
			return false;
		}
	}

	public refreshVersion(): void {
		location.reload();
	}

	signout(event: GenericEvent<HTMLAnchorElement>): void {
		event.stopPropagation();

		this.authService.signout();
		this.cacheService.reset();
		this.permissionsService.flushPermissions();

		clearTimeout(this.timerHandle);
	}

	bingScrollEvent(): void {
		window.addEventListener('scroll', (e) => {
			if (window.scrollY > this.headerContentTop) {
				this.shouldStick = true;
			} else {
				this.shouldStick = false;
			}
		});
	}

	onLangSelect(lang: LangDefinition): void {
		this.transloco.setActiveLang(lang.id);
		this.activeLang = lang.label;
	}

	private createTitle(routesCollection: Breadcrumb[]): string {

		const titles = routesCollection.filter((route) => route.displayName);

		if (this.router.url.indexOf('/customers/' + this.globals.customerId) > -1 && titles.length > 1) {
			titles[1].displayName = this.globals.customerName;
		}

		if (this.router.url.indexOf('/rating/pricebook/') > -1 && titles.length > 1) {
			titles[2].displayName = this.globals.priceBookName;
			this.globals.priceBookProductId = undefined;
		}

		if (this.router.url.indexOf('/rating/ratecard/') > -1 && titles.length > 1) {
			titles[2].displayName = this.globals.rateCardName;
			this.globals.rateCardRateId = undefined;
		}

		if (this.router.url.indexOf('/product-kits/' + this.globals.productKitId) > -1 && titles.length > 1) {
			titles[1].displayName = this.globals.productKitName;
		}

		if (this.router.url.indexOf('/bundles/' + this.globals.bundleId) > -1 && titles.length > 1) {
			titles[1].displayName = this.globals.bundleName;
		}

		if (this.router.url.indexOf('/quantities/' + this.globals.bundleQuantityId) > -1 && titles.length > 1) {
			titles[3].displayName = this.globals.bundleQuantityName;
		}

		if (this.router.url.indexOf('/products/' + this.globals.kitProductCodeId) > -1 && titles.length > 1) {
			titles[3].displayName = this.globals.kitProductCode;
		}

		if (this.router.url.indexOf('/products/' + this.globals.customerProductId) > -1 && titles.length > 1) {
			titles[3].displayName = this.globals.customerProductName;
		}

		if (this.router.url.indexOf('/service-items/' + this.globals.customerServiceItemId) > -1 && titles.length > 1) {
			titles[3].displayName = this.globals.customerServiceItemName;
		}

		if (this.router.url.indexOf('/sites/' + this.globals.customerSiteId) > -1 && titles.length > 1) {
			titles[3].displayName = this.globals.customerSiteName;
		}

		if (this.router.url.indexOf('/users/' + this.globals.customerUserId) > -1 && titles.length > 1) {
			titles[3].displayName = this.globals.customerUserName;
		}

		if (this.router.url.indexOf('/customers/' + this.globals.customerId + '/invoices/') > -1 && titles.length > 1) {
			titles[3].displayName = this.globals.invoiceName;
		}

		if (this.router.url.indexOf('/customers/' + this.globals.customerId + '/assignments/' + this.globals.customerAssignmentId) > -1 && titles.length > 1) {
			titles[3].displayName = this.globals.customerAssignmentName;
		}

		if (this.router.url.indexOf('/transactions/' + this.globals.customerTransactionId) > -1 && titles.length > 1) {
			titles[3].displayName = this.globals.customerTransactionName;
		}

		if (this.router.url.indexOf('/feed/' + this.globals.feedId) > -1 && titles.length > 1) {
			titles[1].displayName = this.globals.feedName;
		}

		if (this.router.url.indexOf('/product-templates/' + this.globals.productTemplateId) > -1 && titles.length > 1) {
			titles[1].displayName = this.globals.productTemplateName;
		}

		if (this.router.url.indexOf('/agreements/' + this.globals.agreementId) > -1 && titles.length > 1) {
			titles[3].displayName = this.globals.agreementName;
		}

		if (this.router.url.indexOf('/reseller-users/users/' + this.globals.resellerUserId) > -1 && titles.length > 1) {
			titles[2].displayName = this.globals.resellerUserName;
		}

		if (this.router.url.indexOf('/reseller-users/roles/' + this.globals.resellerRoleId) > -1 && titles.length > 1) {
			titles[2].displayName = this.globals.resellerRoleName;
		}

		if (this.router.url.indexOf('/service-items/' + this.globals.serviceItemId) > -1 && titles.length > 1) {
			titles[1].displayName = this.globals.serviceItemName;
		}

		if (this.router.url.indexOf('/settings/surcharges/' + this.globals.surchargeId) > -1 && titles.length > 1) {
			titles[2].displayName = this.globals.surchargeName;
		}

		if (this.router.url.indexOf('/settings/payment-terms/' + this.globals.paymentTermId) > -1 && titles.length > 1) {
			titles[2].displayName = this.globals.paymentTerm;
		}

		if (this.router.url.indexOf('/settings/service-item-types/' + this.globals.serviceItemTypeId) > -1 && titles.length > 1) {
			titles[2].displayName = this.globals.serviceItemType;
		}

		if (this.router.url.indexOf('/tax-exemptions/' + this.globals.taxExemptionId) > -1 && titles.length > 1) {
			titles[4].displayName = this.globals.taxExemptionName;
		}

		const routeTitle = this.titlesToString();
		return `${routeTitle}`;
	}

	private titlesToString(): string {
		return `Datagate`;
	}
}
