import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'core/app-config';
import { HttpAuth } from 'core/auth';
import { Notification } from 'domain/models';
import { ApiData, Guid } from 'domain/types';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NotificationRepository {
	constructor(
		private readonly http: HttpAuth,
		private readonly config: AppConfig
	) {}

	async getNotifications(destroy$: Subject<void>, customerId?: Guid): Promise<Notification[]> {
		let params = new HttpParams();

		if (!customerId.isEmpty()) {
			params = params.set('customerId', customerId.toString());
		}
		
		const alertsResponse = await this.http.promise(destroy$).get<ApiData<Notification[]>>(`${this.config.apiUrl}${this.config.apiVersion}/alerts`, { params });
	
		return alertsResponse.body?.data;
	}
	
	async updateNotification(payload: Notification, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/alerts/${payload.id}`, payload);
	
		return res.body;
	}
}
