import { Serializable } from 'domain/models';
import { EntityName } from 'tools/decorators';
import { ProductRatePlan } from './main-entities';
import { Guid } from 'domain/types';
import { ExtraFieldValue } from './extra-fields-entities';

@EntityName('Product Kit', 'Product Kits')
export class ProductKit implements Serializable<ProductKit> {
	id: Guid;
	name: string;
	description: string;
	cost: number;
	sell: number;
	inAdvance: number;
	categoryId: number;
	proRata: boolean;
	excludeSurcharges: boolean;
	doNotExportToCWAgreements: boolean;
	assignedGroups: ExtraFieldValue[];
	deserialize(json: any): ProductKit {
		this.id = json.id;
		this.name = json.name;
		this.description = json.description;
		this.cost = Math.round(json.cost * 10000) / 10000;
		this.sell = Math.round(json.sell * 10000) / 10000;
		this.inAdvance = json.inAdvance;
		this.categoryId = json.categoryId;
		this.proRata = json.proRata;
		this.excludeSurcharges = json.excludeSurcharges;
		this.doNotExportToCWAgreements = json.doNotExportToCWAgreements;
		this.assignedGroups = json.assignedGroups;
		return this;
	}

	clone(): ProductKit {
		return Object.assign(new ProductKit(), this);
	}

	restore(src: ProductKit): void {
		Object.assign(this, src);
	}

}

@EntityName('Kit Product', 'Kit Products')
export class KitProduct implements Serializable<KitProduct> {
	id: Guid;
	cost: number;
	sell: number;
	productName: string;
	productLabel: string;
	productCategory: string;
	categoryId: Guid;
	quantity: number;
	supplierId: Guid;
	templateProductId: Guid;
	transactionCodeId: Guid;
	transactionCode: string;
	glCodeId: Guid;
	glCode: string;
	productRatePlans: ProductRatePlan[];
	bundleId: Guid;
	situsCode: string;
	unitTypeCode: string;
	commissionRate: number;
	public regulatoryCodeId?: number;
	public exemptionCodeId?: number;
	public telecomSpecialTermId: number;
	public avalaraSaleType: number;
	public avalaraSalesAndUseId: number;
	public xeroTrackingCategoryId?: string;
	public xeroTrackingOptionId?: string;
	public taxCodeDescription: string;
	deserialize(json: any): KitProduct {
		this.id = json.productId;

		this.cost = json.cost;
		this.sell = json.sell;
		this.productName = json.productName;
		this.productLabel = json.productLabel;
		this.productCategory = json.productCategory;
		this.categoryId = json.categoryId;
		this.quantity = json.quantity;
		this.supplierId = json.supplierId;
		this.templateProductId = json.templateProductId;
		this.transactionCodeId = json.transactionCodeId;
		this.transactionCode = json.transactionCode;
		this.glCodeId = json.glCodeId;
		this.glCode = json.glCode;
		this.avalaraSaleType = json.avalaraSaleType;
		this.avalaraSalesAndUseId = json.avalaraSalesAndUseId;
		this.bundleId = json.bundleId;
		this.situsCode = json.situsCode;
		this.unitTypeCode = json.unitTypeCode;
		this.regulatoryCodeId = json.regulatoryCodeId;
		this.exemptionCodeId = json.exemptionCodeId;
		this.xeroTrackingCategoryId = json.xeroTrackingCategoryId;
		this.xeroTrackingOptionId = json.xeroTrackingOptionId;
		this.telecomSpecialTermId = json.telecomSpecialTermId;
		this.commissionRate = json.commissionRate;
		this.taxCodeDescription = json.taxCodeDescription;

		return this;
	}

	clone(): KitProduct {
		return Object.assign(new KitProduct(), this);
	}
}

export interface CustomerProductKitProduct {
	productName: string;
	productLabel: string;
	supplierId: Guid;
	templateProductId: Guid;
	productId: Guid;
	glCodeId: Guid;
	bundleId: Guid;
	categoryId: Guid;
	regulatoryCodeId?: number;
	exemptionCodeId?: number;
	telecomSpecialTermId: number;
	avalaraSaleType: number;
	avalaraSalesAndUseId: number;
	situsCode: string;
	unitTypeCode: string;
	transactionCodeId: Guid;
	productRatePlans?: ProductRatePlan[];
	xeroTrackingCategoryId?: string;
	xeroTrackingOptionId: string;
	productCategory: string;
	glCode: string;
	commissionRate: number;
	taxCodeDescription: string;
	transactionCode: string;
}
