import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { ApiData, Guid } from 'domain/types';
import { ProductCategory } from 'domain/entities';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class ProductCategoryRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	getProductCategory$(): Observable<ProductCategory[]> {
		const key = 'product-category';

		if (this.cacheHasKey(key)) {
			return this.getCache<ProductCategory[]>(key);
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/product-categories`)
				.pipe(
					map(res => {
						const productCategories = res.body.data.map(x => new ProductCategory().deserialize(x));
						this.setCacheValue(key, productCategories, null);
						return productCategories;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	async getProductCategories(destroy$?: Subject<void>): Promise<ProductCategory[]> {
		const res = await this.http.promise(destroy$).get<ApiData<ProductCategory[]>>(`${this.config.apiUrl}${this.config.apiVersion}/product-categories`);

		return res?.body.data.map(x => new ProductCategory().deserialize(x));
	}

	async getProductCategoryById(id: Guid, destroy$?: Subject<void>): Promise<ProductCategory> {
		const res = await this.http.promise(destroy$).get<ApiData<ProductCategory>>(`${this.config.apiUrl}${this.config.apiVersion}/product-categories/${id}`);

		return res.body?.data;
	}

	public addProductCategory(code: ProductCategory): Observable<Guid> {
		const key = 'product-category';

		return this.createEntity(`/product-category`, code)
			.pipe(
				tap(s => this.addCacheCollectionItem(key, s))
				, map(s => s.id));
	}

	public updateProductCategory(productCategory: ProductCategory): Observable<unknown> {
		const key = 'product-category';

		return this.updateEntity(`/product-category/${productCategory.id}`, productCategory)
			.pipe(tap(s => this.updateCacheCollectionItem(key, productCategory.id, productCategory)));
	}

	public deleteProductCategory(ids: Guid[]): Observable<HttpResponse<void>> {
		const key = 'product-category';
		return this.deleteEntities(`/product-categories/delete`, ids)
			.pipe(
				tap(() => this.removeManyFromCacheCollection(key, ids)));
	}
}
