<ag-grid-angular class="ag-theme-material enterprise" [gridOptions]="gridOptions"> </ag-grid-angular>

<dg-modal [open]="selectedNotification !== undefined">
	<form class="modal__content-body" [formGroup]="form">
		<div class="page-tile__body page-tile__body--hidden-none">
			<div class="page-tile__title">
				{{'dg-notifications-list.modal_title' | transloco}}
			</div>

            <dg-tile-input
                formControlName="emailTo"
                [id]="'txtEmailTo'"
                [label]="'dg-notifications-list.modal-fields_emailTo'"
                [name]="'emailTo'">
            </dg-tile-input>

			<div class="form__bottom">
				<button
					type="button" class="btn btn--default margin-r__20"
					(click)="onNotificationSettingsClose()">
					{{'global.modals_btn-cancel' | transloco}}
				</button>
				<button type="button" class="btn btn--secondary width-100px"
					(click)="onNotificationUpdate()">
					{{!isSaving ? ('global.modals_btn-save' | transloco) : ''}} 
					<i *ngIf="isSaving" class="fa fa-spinner fa-spin btn-spinner"></i>
				</button>
			</div>
		</div>
	</form>
</dg-modal>