
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from 'core/app-config';
import { CustomerSite, ExemptionCode, ExtraFieldEntity, IntegrationType, Rating } from 'domain/entities';
import { ExtraFieldType } from 'domain/enums';
import { Guid } from 'domain/types';
import { CustomerRepository, RatingRepository, SettingsRepository, SureTaxCodeRepository } from 'repositories';
import { Subject, takeUntil } from 'rxjs';
import { RetrieveResultData } from 'repositories/address-lookup.repository';
import { Globals } from 'tools/globals';
import { permissions } from 'tools/permissions';
import { CustomerSiteService } from './customer-site.service';

@Component({
	selector: 'dg-customer-site',
	templateUrl: './dg-customer-site.component.html',
	styleUrls: ['./dg-customer-site.component.sass']
})

export class DgCustomerSiteComponent implements OnInit, OnDestroy {
	@Input() isModalView = false;
	@Output() saveClicked = new EventEmitter<CustomerSite>();
	@Output() resetClicked = new EventEmitter();
	@Output() backToClicked = new EventEmitter();

	private selectedEntityOrig: CustomerSite;
	private customerId = new Guid(this.route.snapshot.params.customerId);
	private customerSiteId = new Guid(this.route.snapshot.params.customerSiteId);
	public isCustomerSiteAdd = false;

	public form = this.fb.group({
		name: this.fb.control<string>(undefined, [Validators.required]),
		siteCode: this.fb.control<string>(undefined),
		address1: this.fb.control<string>(undefined),
		address2: this.fb.control<string>(undefined),
		city: this.fb.control<string>(undefined),
		externalReference: this.fb.control<string>(undefined),
		country: this.fb.control<string>(undefined),
		cchCode: this.fb.control<string>(undefined),
		postalCode: this.fb.control<string>(undefined),
		referenceCode: this.fb.control<string>(undefined),
		exemptionCode: this.fb.control<string>(undefined),
		exemptionCodeId: this.fb.control<number>(undefined),
		csiTaxExemptionCode: this.fb.control<string>(undefined),
		state: this.fb.control<string>(undefined),
		csiTaxExemptionList: this.fb.control<string>(undefined),
		rateCardId: this.fb.control<number>(undefined),
		incorporated: this.fb.control<boolean>(false),
		hideTaxes: this.fb.control<boolean>(false),
		contactPhone: this.fb.control<string>(undefined),
		extraField1: this.fb.control<string>(undefined),
		extraField2: this.fb.control<string>(undefined),
		extraField3: this.fb.control<string>(undefined),
		extraField4: this.fb.control<string>(undefined),
		extraField5: this.fb.control<string>(undefined),
	});
	public selectedEntity = new CustomerSite();
	public exemptionCodes: ExemptionCode[] = [];
	public ratings: Rating[] = [];
	public isDiscard = false;
	public addressData: RetrieveResultData;
	public permissions = permissions;
	public isPostalRequired = false;
	public isCSI = false;
	public isAvalaraCommunication = false;
	public isSureTax = false;
	public isSaving$ = this.customerSiteService.isSaving$;

	public extraFieldsLabels: ExtraFieldEntity;
	private destroy$ = new Subject<void>();
	constructor(
		private readonly route: ActivatedRoute,
		private readonly fb: FormBuilder,
		private readonly customerRepository: CustomerRepository,
		private readonly ratingRepository: RatingRepository,
		private readonly customerSiteService: CustomerSiteService,
		private readonly sureTaxCodeRepository: SureTaxCodeRepository,
		private readonly globals: Globals,
		private readonly settingsRepository: SettingsRepository,
		readonly config: AppConfig
	) {
		this.isCSI = config.integrationSettings.hasTaxEngine(IntegrationType.Csi);
		this.isAvalaraCommunication = config.integrationSettings.hasTaxEngine(IntegrationType.AvalaraCommunications);
		this.isSureTax = config.integrationSettings.hasTaxEngine(IntegrationType.Suretax);

		this.form.controls.country.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(code => this.onCountryChanged(code));

		this.form.controls.address1.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(val => this.onAnyAddressChange(this.selectedEntity.address1, val));

		this.form.controls.address2.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(val => this.onAnyAddressChange(this.selectedEntity.address2, val));

		this.form.controls.city.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(val => this.onAnyAddressChange(this.selectedEntity.city, val));

		this.form.controls.postalCode.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(val => this.onAnyAddressChange(this.selectedEntity.postalCode, val));

		this.form.controls.country.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(val => this.onAnyAddressChange(this.selectedEntity.country, val));

		this.form.controls.state.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(val => this.onAnyAddressChange(this.selectedEntity.state, val));
	}

	async ngOnInit(): Promise<void> {
		if (!this.customerSiteId.isEmpty()) {
			this.selectedEntity = await this.customerRepository.getCustomerSiteById(this.customerId, this.customerSiteId,this.destroy$).withBusy();
		}

		const [ratings, extraFieldsLabels, exemptionCodes] = await Promise.all([
			this.ratingRepository.getAllRating(false, this.destroy$),
			this.settingsRepository.getExtraFieldsLabels(this.destroy$),
			this.sureTaxCodeRepository.getExemptionCodes(this.destroy$)
		]).withBusy();

		this.ratings = ratings;
		this.exemptionCodes = exemptionCodes;
		this.extraFieldsLabels = extraFieldsLabels.find(e => e.extraFieldTypeId === ExtraFieldType.Site);

		if (this.selectedEntity.id) {
			this.selectedEntityOrig = this.selectedEntity.clone();
			this.globals.customerSiteName = this.selectedEntity.name;
			this.globals.customerSiteId = this.selectedEntity.id;
			this.isDiscard = true;
		} else {
			this.isCustomerSiteAdd = true;
		}

		this.fillForm(this.selectedEntity);

		if (this.selectedEntityOrig?.country) {
			this.onCountryChanged(this.selectedEntityOrig.country);
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private fillForm(ent: CustomerSite): void {
		this.form.patchValue({
			name: ent.name,
			siteCode: ent.siteCode,
			address1: ent.address1,
			address2: ent.address2,
			city: ent.city,
			postalCode: ent.postalCode,
			country: ent.country,
			state: ent.state,
			cchCode: ent.cchCode,
			referenceCode: ent.referenceCode,
			externalReference: ent.externalReference,
			exemptionCodeId: ent.exemptionCodeId,
			exemptionCode: ent.exemptionCode,
			csiTaxExemptionCode: ent.csiTaxExemptionCode,
			csiTaxExemptionList: ent.csiTaxExemptionList,
			rateCardId: ent.rateCardId,
			incorporated: ent.incorporated,
			hideTaxes: ent.hideTaxes,
			contactPhone: ent.contactPhone,
			extraField1: ent.extraField1,
			extraField2: ent.extraField2,
			extraField3: ent.extraField3,
			extraField4: ent.extraField4,
			extraField5: ent.extraField5,
		});
	}

	private onCountryChanged(code: string): void {
		const postalCodeControl = this.form.controls.postalCode;
		const isUS = code === 'US';

		postalCodeControl.setValidators(isUS ? [Validators.required] : []);
		this.isPostalRequired = isUS;
		postalCodeControl.updateValueAndValidity();
	}

	private mapFormToEntity(entity: CustomerSite): CustomerSite {
		const formValue = this.form.value;

		entity.name = formValue.name;
		entity.address1 = formValue.address1;
		entity.address2 = formValue.address2;
		entity.city = formValue.city;
		entity.postalCode = formValue.postalCode;
		entity.country = formValue.country;
		entity.state = formValue.state;
		entity.cchCode = formValue.cchCode;
		entity.referenceCode = formValue.referenceCode;
		entity.externalReference = formValue.externalReference;
		entity.exemptionCode = formValue.exemptionCode;
		entity.exemptionCodeId = formValue.exemptionCodeId;
		entity.incorporated = formValue.incorporated ? formValue.incorporated : false;

		if (entity.exemptionCodeId) {
			entity.exemptionCode = this.exemptionCodes.find(x => x.id === this.selectedEntity.exemptionCodeId).code;
		} else {
			entity.exemptionCode = null;
		}

		entity.csiTaxExemptionCode = formValue.csiTaxExemptionCode;
		entity.csiTaxExemptionList = formValue.csiTaxExemptionList;
		entity.rateCardId = formValue.rateCardId;
		entity.hideTaxes = formValue.hideTaxes;
		entity.contactPhone = formValue.contactPhone;

		for (let i = 1; i < 6; i++) {
			entity['extraField' + i] = formValue['extraField' + i];
		}

		return entity;
	}

	private mapFormToValidationEntity(entity: CustomerSite): RetrieveResultData {
		const formValue = this.form.value;

		return {
			addressLine1: formValue.address1,
			addressLine2: formValue.address2,
			buildingFloorNumber: entity.buildingFloorNumber,
			buildingFloorNumberSuffix: entity.buildingFloorNumberSuffix,
			buildingFloorType: entity.buildingFloorType,
			buildingLocation: entity.buildingLocation,
			buildingProperty: entity.buildingProperty,
			city: formValue.city,
			country: formValue.country,
			locality: entity.locality,
			postalCode: formValue.postalCode,
			state: entity.state,
			streetFirstName: entity.streetFirstName,
			streetFirstSuffix: entity.streetFirstSuffix,
			streetFirstType: entity.streetFirstType,
			streetHouseFirstNumber: entity.streetHouseFirstNumber,
			streetHouseFirstNumberSuffix: entity.streetHouseFirstNumberSuffix,
			streetHouseSecondNumber: entity.streetHouseSecondNumber,
			streetHouseSecondNumberSuffix: entity.streetHouseSecondNumberSuffix,
			streetSecondName: entity.streetSecondName,
			streetSecondSuffix: entity.streetSecondSuffix,
			streetSecondType: entity.streetSecondType,
			unitFirstNumber: entity.unitFirstNumber,
			unitFirstSuffix: entity.unitFirstSuffix,
			unitSecondNumber: entity.unitSecondNumber,
			unitSecondSuffix: entity.unitSecondSuffix,
			unitType: entity.unitType
		};
	}

	resetForm(): void {
		this.form.markAsPristine();
		this.form.markAsUntouched();
		this.form.updateValueAndValidity();
	}

	private onAnyAddressChange(old: string, change: string): void {
		if (this.selectedEntity.validated && old !== change) {
			this.selectedEntity.validated = null;
		}
	}

	public backTo(): void {
		this.backToClicked.emit();
	}

	public onSubmit(): void {
		this.mapFormToEntity(this.selectedEntity);

		this.saveClicked.emit(this.selectedEntity);
	}

	public discard(): void {
		const ent = this.selectedEntityOrig.clone();

		if (ent) {
			this.fillForm(ent);
		}

		this.resetForm();
	}

	public onAddressData(result: RetrieveResultData): void {
		this.selectedEntity.buildingFloorNumber = result.buildingFloorNumber;
		this.selectedEntity.buildingFloorNumberSuffix = result.buildingFloorNumberSuffix;
		this.selectedEntity.buildingFloorType = result.buildingFloorType;
		this.selectedEntity.buildingLocation = result.buildingLocation;
		this.selectedEntity.buildingProperty = result.buildingProperty;
		this.selectedEntity.locality = result.locality;
		this.selectedEntity.streetFirstName = result.streetFirstName;
		this.selectedEntity.streetFirstSuffix = result.streetFirstSuffix;
		this.selectedEntity.streetFirstType = result.streetFirstType;
		this.selectedEntity.streetHouseFirstNumber = result.streetHouseFirstNumber;
		this.selectedEntity.streetHouseFirstNumberSuffix = result.streetHouseFirstNumberSuffix;
		this.selectedEntity.streetHouseSecondNumber = result.streetHouseSecondNumber;
		this.selectedEntity.streetHouseSecondNumberSuffix = result.streetHouseSecondNumberSuffix;
		this.selectedEntity.streetSecondName = result.streetSecondName;
		this.selectedEntity.streetSecondSuffix = result.streetSecondSuffix;
		this.selectedEntity.streetSecondType = result.streetSecondType;
		this.selectedEntity.unitFirstNumber = result.unitFirstNumber;
		this.selectedEntity.unitFirstSuffix = result.unitFirstSuffix;
		this.selectedEntity.unitSecondNumber = result.unitSecondNumber;
		this.selectedEntity.unitSecondSuffix = result.unitSecondSuffix;
		this.selectedEntity.unitType = result.unitType;
		this.selectedEntity.state = result.state;

		this.form.patchValue({
			address1: result.addressLine1,
			address2: result.addressLine2,
			country: result.country,
			city: result.city,
			postalCode: result.postalCode,
			state: result.state
		});

		this.selectedEntity.validated = true;
	}

	public onValidationAnswer(result: boolean): void {
		if (this.selectedEntity.validated !== result) {
			this.form.markAsDirty();
		}
		this.selectedEntity.validated = result;
	}

	public onValidationSubmit(): void {
		this.addressData = this.mapFormToValidationEntity(this.selectedEntity);
	}
}
