import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { ApiData, Guid } from 'domain/types';
import { GlCode } from 'domain/entities';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class GlCodesRepository extends BaseRepository {
	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	getGlCodes$(): Observable<GlCode[]> {
		const key = 'gl-codes';
		const condition = this.cache.get('gl-codes-needsRefresh');

		if (this.cacheHasKey(key) && condition != true) {
			return this.getCache<GlCode[]>(key);
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/general-ledger-codes`)
				.pipe(
					map(res => {
						const glCodes = res.body.data.map(x => new GlCode().deserialize(x));
						this.setCacheValue(key, glCodes, null);
						this.cache.set('gl-codes-needsRefresh', false);
						return glCodes;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	async getGlCodes(destroy$?: Subject<void>): Promise<GlCode[]> {
		const res = await this.http.promise(destroy$).get<ApiData<GlCode[]>>(`${this.config.apiUrl}${this.config.apiVersion}/general-ledger-codes`);

		return res?.body.data.map(x => new GlCode().deserialize(x));
	}
	
	getGlCodeById(id: Guid): Observable<GlCode> {
		const key = `gl-codes-${id}`;

		if (this.cacheHasKey(key)) {
			return this.getCache<GlCode[]>(key)
				.pipe(map(site => site.find(x => x.id.equals(id))));
		}

		return this.getGlCodes$().pipe(map(gl => gl.find(x => x.id.equals(id))));
	}

	public updateGlCodes(code: GlCode): Observable<any> {
		const key = 'gl-codes';

		return this.updateEntity(`/general-ledger-code/${code.id}`, code)
			.pipe(
				tap(t => this.updateCacheCollectionItem(key, t.id, t))
				, map(s => null));
	}

	public addGlCode(code: GlCode): Observable<Guid> {

		const key = 'gl-codes';

		return this.createEntity(`/general-ledger-code`, code)
			.pipe(
				tap(s => this.addCacheCollectionItem(key, s))
				, map(s => s.id));
	}

	public deleteGlCode(ids: Guid[]): Observable<HttpResponse<void>> {

		const key = 'gl-codes';

		const observable =
			this.deleteEntities(`/general-ledger-codes/delete`, ids)
				.pipe(
					tap(() => this.removeManyFromCacheCollection(key, ids)),
					tap(() => this.cache.set('gl-codes-needsRefresh', true)
					));

		return observable;
	}
}
