import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { Guid } from 'domain/types';

export interface CheckboxCellParams<T> { 
	id?: Guid;
	checked?: boolean;
	onClick: (node: IRowNode<T>) => void | Promise<void>;
	busy: boolean; 
}

type CheckboxParams<T> = ICellRendererParams<T> & CheckboxCellParams<T>;

@Component({
	selector: 'dg-checkbox-cell-renderer',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `<dg-checkbox 
				*ngIf="!params.busy" 
				[id]="id"
				[isGridCheckbox]="true" 
				[(ngModel)]="params.checked" 
				(change)="onCheckboxClick($event, params)" 
				ngDefaultControl>
			</dg-checkbox>
		<i *ngIf="params.busy" class="fa fa-spinner fa-spin btn-spinner" aria-hidden="true"></i>`
})
export class CheckboxCellRendererComponent<T> implements ICellRendererAngularComp {
	params: CheckboxParams<T>;
	id: string;
	constructor(private readonly _cdr: ChangeDetectorRef) {}

	agInit(params: CheckboxParams<T>): void {
		this.params = params;
		this.id = this.params?.id?.toString();
		this._cdr.markForCheck();
	}

	refresh(params: CheckboxParams<T>): boolean {
		this.params = params;
		this._cdr.markForCheck();
		return true;
	}

	onCheckboxClick(event: Event, input: CheckboxCellParams<T>): void {
		event.stopPropagation();
		
		if (input.onClick instanceof Function) {
			void input.onClick(this.params.node);
		}

		this._cdr.markForCheck();
	}
}
