import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'core/app-config';
import { HttpAuth } from 'core/auth';
import { ServiceSupplier } from 'domain/models';
import { ApiData, Guid } from 'domain/types';
import { Subject } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class ServiceSuppliersRepository {
	constructor(
		private readonly http: HttpAuth,
		private readonly config: AppConfig
	) {}

	async getServiceSuppliers(destroy$?: Subject<void>): Promise<ServiceSupplier[]> {
		const res = await this.http.promise(destroy$).get<ApiData<ServiceSupplier[]>>(`${this.config.apiUrl}${this.config.apiVersion}/suppliers`);

		return res.body.data;
	}

	async getServiceSupplierById(id: Guid, destroy$: Subject<void>): Promise<ServiceSupplier | null> {
		const serviceSuppliers = await this.getServiceSuppliers(destroy$);
		const serviceSupplier = serviceSuppliers.find(serviceSupplier => serviceSupplier.id.equals(id));

		if (!serviceSupplier) {
			throw new Error(`Service Supplier not found`);
		}

		return serviceSupplier;
	}

	async addServiceSupplier(instance: ServiceSupplier, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/suppliers`, instance);

		return res.body;
	}

	async updateServiceSupplier(id: Guid, instance: ServiceSupplier, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/suppliers/${id}`, instance);

		return res.body;
	}

	async deleteServiceSupplier(id: Guid, destroy$?: Subject<void>): Promise<HttpResponse<void>>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/suppliers/${id}`);
		return res.body;
	}

	async deleteServiceSuppliers(records: Guid[], destroy$?: Subject<void>): Promise<HttpResponse<void>> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/suppliers/delete`, { records });
		return res.body;
	}
}
