import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'dg-scrollable',
	templateUrl: './dg-scrollable.component.html',
	styleUrls: ['./dg-scrollable.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DgScrollableComponent implements OnInit, OnDestroy {
	@ViewChild('scrollableContainer') elementRef: ElementRef<HTMLElement>;
	@Input() scrollPixels = 100;
	@Input() showArrows = true;
	isOverflow = false;
	private readonly unsubscribe$ = new Subject<void>();
	constructor(private cdr: ChangeDetectorRef, private ngZone: NgZone) {}

	ngOnInit(): void {
		this.ngZone.onStable.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
			this.updateOverflowState();
			this.cdr.markForCheck();
		});
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	@HostListener('window:resize')
	onWindowResize(): void {
		this.updateOverflowState();
		this.cdr.markForCheck();
	}

	private updateOverflowState(): void {
		const { scrollWidth, clientWidth } = this.elementRef.nativeElement;

		this.isOverflow = scrollWidth > clientWidth;
		this.cdr.markForCheck();
	}

	get canScrollStart(): boolean {
		return this.elementRef.nativeElement.scrollLeft > 0;
	}

	get canScrollEnd(): boolean {
		const element = this.elementRef.nativeElement;
		return element.scrollLeft + element.clientWidth < element.scrollWidth;
	}

	scroll(direction: number): void {
		this.elementRef.nativeElement.scrollLeft += this.scrollPixels * direction;
		this.cdr.markForCheck();
	}
}
