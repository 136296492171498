import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import { HttpAuth } from 'core/auth';
import { AgreementEntity } from 'domain/entities';
import { CacheService } from 'services/cache.service';
import { BaseRepository } from './base-repository';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { Guid } from 'domain/types';

@Injectable({
	providedIn: 'root'
})
export class AgreementsRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {

		super(http, config, cache);
	}

	async getCustomerAgreementsAsync(customerId: Guid, displayOption: number, destroy$: Subject<void>): Promise<AgreementEntity[]> {
		const key = `customer-${customerId}-agreements`;
		const condition = this.cache.get('agreements-' + customerId + '-needsRefresh');
		const reset = this.cache.get('needToResetAgreements');

		if (reset === true) {
			this.cache.removeKey(`customer-${customerId}-agreements`);
		}

		if (this.cacheHasKey(key) && condition !== true) {
			return this.getCacheSync<AgreementEntity[]>(key);
		}

		const res = await this.http.promise(destroy$).get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/agreements?displayOption=${displayOption}`);
		const agreements = res.body.data.map(x => new AgreementEntity().deserialize(x));

		this.setCacheValue(key, agreements, null);
		this.cache.set('agreements-' + customerId + '-needsRefresh', false);

		return agreements;
	}

	getCustomerAgreements(customerId: Guid): Observable<AgreementEntity[]> {
		const key = `customer-${customerId}-agreements`;
		const condition = this.cache.get('agreements-' + customerId + '-needsRefresh');
		const reset = this.cache.get('needToResetAgreements');

		if (reset === true) {
			this.cache.removeKey(`customer-${customerId}-agreements`);
		}

		if (this.cacheHasKey(key) && condition !== true) {
			return this.getCache<AgreementEntity[]>(key);
		}

		//DAT-2765 - somehow got undefined customer Id
		if (!customerId) {
			return of([]);
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/agreements`)
				.pipe(
					map(res => {
						const agreements = res.body.data.map(x => new AgreementEntity().deserialize(x));
						this.setCacheValue(key, agreements, null);

						this.cache.set('agreements-' + customerId + '-needsRefresh', false);
						return agreements;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	getCustomerAgreementById(customerId: Guid, id: Guid): Observable<AgreementEntity> {
		const key = `customer-${customerId}-agreements-${id}`;

		if (this.cacheHasKey(key)) {
			return this.getCache<AgreementEntity[]>(key)
				.pipe(map(agreement => agreement.find(x => x.id.equals(id))));
		}

		return this.getCustomerAgreements(customerId).pipe(map(agreement => agreement.find(x => x.id.equals(id))));
	}

	public addCustomerAgreement(customerId: Guid, agreement: AgreementEntity): Observable<Guid> {

		const key = `customer-${customerId}-agreements`;

		return this.createEntity(`/customers/${customerId}/agreements`, agreement)
			.pipe(
				tap(s => this.addCacheCollectionItem(key, s))
				, map(s => s.id));
	}

	public updateCustomerAgreement(customerId: Guid, agreement: AgreementEntity): Observable<void> {

		const key = `customer-${customerId}-agreements`;

		return this.updateEntity(`/customers/${customerId}/agreements/${agreement.id}`, agreement)
			.pipe(
				tap(s => this.updateCacheCollectionItem(key, agreement.id, agreement))
				, map(s => null));
	}

	public deleteAgreement(customerId: Guid, agreementId: Guid): Observable<void> {

		const key = `customer-${customerId}-agreements`;

		return super.deleteEntity(`/customers/${customerId}/agreements/${agreementId}`)
			.pipe(
				tap(s => this.removeFromCacheCollection(key, agreementId))
				, map(s => null));
	}
}
