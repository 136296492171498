import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { HttpAuth } from 'core/auth';
import {
	AuthorizeEntity,
	ConnectwiseSettings,
	SureTaxRegCodeEntity,
	SureTaxEntity,
	QuickBooksEntity,
	StripeEntity,
	WebHookEntity,
	CsiTaxEntity,
	XeroEntity,
	QuickBookExportType,
	AvalaraEntity,
	AvalaraCompany,
	AvalaraCommunication,
	AvalaraTaxExemption,
	AvalaraLocation,
	AvalaraTaxTypes,
	AvalaraSalesAndUse,
	QuoterEntity,
	QuoteEntity,
	AgreementEntity,
	IppayEntity,
	AutotaskEntity,
	AutotaskContractCategory,
	QBDEntity,
	MonerisEntity,
	ItQuoterEntity,
	KaseyaEntity,
	PinchEntity,
	HaloEntity,
	NexioEntity,
	QuoteDetails,
	QuotePreviewItem,
} from 'domain/entities';
import { CacheService } from 'services/cache.service';
import { BaseRepository } from './base-repository';
import { QbTaxCode } from 'domain/entities/qb-entities';
import { AppConfig } from 'core/app-config';
import { Guid, ApiData } from 'domain/types';
import { hasApiError } from 'domain/utils';
import { CeretaxDataLookups, CereTaxEntity, CeretaxTaxExemption, IntegrationEntity, IntegrationEntityResponse, IpndEntity, QuickBookPaymentMethod } from 'domain/models';
import { IntegrationSettingsRepository } from './integration-settings-repository';

@Injectable({
	providedIn: 'root'
})
export class IntegrationRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService,
		protected readonly integrationSettingsRepository: IntegrationSettingsRepository
	) {
		super(http, config, cache);
	}

	async getAvailableIntegration(destroy$?: Subject<void>): Promise<IntegrationEntity[]> {
		const res = await this.http.promise(destroy$).get<ApiData<IntegrationEntityResponse[]>>(`${this.config.apiUrl}${this.config.apiVersion}/available-payment-integrations`);

		const convertedIntegrations = Object.entries(res.body?.data).map(([id, name]) => ({
			id: Number(id),
			name: name
		}));

		return <IntegrationEntity[]>convertedIntegrations || [];
	}


	// ======== CSI INTEGRATION ===========
	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getCsiTaxIntegration$(): Observable<any> {

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/csi-tax-settings`)
			.pipe(
				map(res => {
					return res.body.data.errorCode ? false : res.body.data.map(x => new CsiTaxEntity().deserialize(x));
				}));
	}

	async getCsiTaxIntegration(destroy$?: Subject<void>): Promise<CsiTaxEntity[]> {
		const res = await this.http.promise(destroy$).get<ApiData<CsiTaxEntity[]>>(`${this.config.apiUrl}${this.config.apiVersion}/csi-tax-settings`);
		return res.body.data.errorCode ? undefined : res.body.data.map(x => new CsiTaxEntity().deserialize(x));
	}

	public addCsiTaxIntegration(settings: CsiTaxEntity): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/csi-tax-settings`, settings, null)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response))))
			);
	}

	public deleteCsiTaxIntegration(): Observable<any> {

		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/csi-tax-settings`)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response))))
			);
	}

	public updateCsiTaxIntegration(settings: CsiTaxEntity): Observable<void> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/csi-tax-settings`, null, settings)
			.pipe(catchError(err => of(err)));
	}

	testCsiTaxAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/csi-tax-settings/test`)
			.pipe(
				map(res => {
					return res.status === 200;
				})
				, catchError(() => of(false)));
	}

	// ======== END OF CSI INTEGRATION ===========

	// ======== SURETAX INTEGRATION ===========

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getSureTaxIntegration$(): Observable<any> {

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/sure-tax-settings`)
			.pipe(
				map(res => {
					return res.body.data.errorCode ? false : res.body.data.map(x => new SureTaxEntity().deserialize(x));
				}));
	}

	async getSureTaxIntegration(destroy$?: Subject<void>): Promise<SureTaxEntity[]> {
		const res = await this.http.promise(destroy$).get<ApiData<SureTaxEntity[]>>(`${this.config.apiUrl}${this.config.apiVersion}/sure-tax-settings`);
		return res.body.data.errorCode ? undefined : res.body.data.map(x => new SureTaxEntity().deserialize(x));
	}

	getRegulatoryCodes$(): Observable<SureTaxRegCodeEntity[]> {
		return this.http.get<ApiData<SureTaxRegCodeEntity[]>>(`${this.config.apiUrl}${this.config.apiVersion}/sure-tax-regulatory-codes`)
			.pipe(
				map(res => {
					return res.body?.data?.map(x => new SureTaxRegCodeEntity().deserialize(x));
				}));
	}

	async getRegulatoryCodes(destroy$?: Subject<void>): Promise<SureTaxRegCodeEntity[]> {
		const res = await this.http.promise(destroy$).get<ApiData<SureTaxRegCodeEntity[]>>(`${this.config.apiUrl}${this.config.apiVersion}/sure-tax-regulatory-codes`);
		return res.body?.data?.map(x => new SureTaxRegCodeEntity().deserialize(x));
	}

	public addSureTaxIntegration(settings: SureTaxEntity): Observable<any> {

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/sure-tax-settings`, settings, null)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response))))
			);
	}

	public deleteSureTaxIntegration(id: number): Observable<any> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/sure-tax-settings/${id}`)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response))))
			);
	}

	public updateSureTaxIntegration(id: Guid, settings: SureTaxEntity): Observable<void> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/sure-tax-settings/${id}`, null, settings)
			.pipe(catchError(err => of(err)));
	}

	testSureTaxAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/sure-tax/test`)
			.pipe(map(res => {
				return res.status === 200;
			})
			, catchError(() => of(false)));
	}

	// ======== END OF SURETAX INTEGRATION ===========

	// ======== AUTOTASK INTEGRATION ===========

	getAutotaskContractCategory(): Observable<AutotaskContractCategory[]> {
		return this.http.get<ApiData<AutotaskContractCategory[]>>(`${this.config.apiUrl}${this.config.apiVersion}/autotask/contract-category`)
			.pipe(
				map(res => res.body.data),
				catchError(() => of([])));
	}

	getAutotaskIntegration(): Observable<any> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/autotask-setting`)
			.pipe(
				map(res => {
					return res.body.data.errorCode ? false : new AutotaskEntity().deserialize(res.body.data);
				})
			);
	}

	public addAutotaskIntegration(settings: AutotaskEntity): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/autotask-setting`, settings, null)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response))))
			);
	}

	public updateAutotaskIntegration(settings: AutotaskEntity): Observable<HttpResponse<void>> {
		return this.http.put<void>(`${this.config.apiUrl}${this.config.apiVersion}/autotask-setting`, null, settings);
	}

	public deleteAutotaskIntegration(): Observable<any> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/autotask-setting`)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response))))
			);
	}

	testAutotaskAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/autotask-setting/test`)
			.pipe(
				map(res => {
					return res.status === 200;
				})
				, catchError(() => of(false)));
	}

	autotaskForcySync(): Observable<HttpResponse<void>> {
		return this.http.post<void>(`${this.config.apiUrl}${this.config.apiVersion}/autotask/force-products-sync`, null, null);
	}

	// ======== END OF AUTOTASK INTEGRATION ===========

	// ======== CONENCTWISE INTEGRATION ===========

	async getConnectWiseIntegration(destroy$?: Subject<void>): Promise<ConnectwiseSettings> {
		const res = await this.http.promise(destroy$).get<ApiData<ConnectwiseSettings>>(`${this.config.apiUrl}${this.config.apiVersion}/connectwise-settings`);

		return res.body?.data?.errorCode ? new ConnectwiseSettings() : new ConnectwiseSettings().deserialize(res.body?.data[0]);
	}

	public addConnectWiseIntegration(settings: ConnectwiseSettings): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/connectwise-settings`, settings, null)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response))))
			);
	}

	testConnectWiseAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/test`)
			.pipe(
				map(res => {
					return res.status === 200;
				}));
	}

	public updateConnectWiseIntegration(id: Guid, settings: ConnectwiseSettings): Observable<any> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/connectwise-settings/${id}`, null, settings);
	}

	public deleteConnectWiseIntegration(id: Guid): Observable<any> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/connectwise-settings/${id}`)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response))))
			);
	}

	connectWiseForcySync(): Observable<HttpResponse<void>> {
		return this.http.post<void>(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/products-sync/force`, null, null);
	}

	// ======== END OF CONNECTWISE INTEGRATION ===========

	// ======== AUTHORIZE INTEGRATION ===========

	async getAuthorizeIntegration(destroy$?: Subject<void>): Promise<AuthorizeEntity> {
		const authorizeResponse = await this.http.promise(destroy$).get<ApiData<AuthorizeEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/authorizenet`);

		return authorizeResponse.body?.data[0] ? new AuthorizeEntity().deserialize(authorizeResponse.body?.data[0]) : new AuthorizeEntity();
	}

	testAuthorizeAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/authorizenet/test`)
			.pipe(
				map(res => res.status === 200)
				, catchError(() => of(false)));
	}

	public addAuthorizeIntegration(settings: AuthorizeEntity): Observable<Guid> {
		return this.http.post<Guid>(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/authorizenet`, settings)
			.pipe(map(res => res.body));
	}

	public updateAuthorizeIntegration(settings: AuthorizeEntity): Observable<any> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/authorizenet`, null, settings);
	}

	async deleteAuthorizeIntegration(destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/authorizenet`);
		return res.body;
	}

	// ======== END OF AUTHORIZE INTEGRATION ===========

	// ======== IPND INTEGRATION ===========

	async getIpndIntegration(destroy$?: Subject<void>): Promise<IpndEntity> {
		const ipndResponse = await this.http.promise(destroy$).get<IpndEntity>(`${this.config.apiUrl}${this.config.apiVersion}/settings/ipnd`);
		return ipndResponse.body ? new IpndEntity().deserialize(ipndResponse.body) : new IpndEntity();
	}

	async addIpndIntegration(instance: IpndEntity, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/settings/ipnd`, instance);
		return res.body;
	}

	async updateIpndIntegration(instance: IpndEntity, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/settings/ipnd/${instance.id}`, instance);
		return res.body;
	}

	async deleteIpndIntegration(id: Guid, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/settings/ipnd/${id}`);
		return res.body;
	}

	async getIpndListTypes(destroy$?: Subject<void>): Promise<any> {
		const ipndListTypes = await this.http.promise(destroy$).get<any>(`${this.config.apiUrl}${this.config.apiVersion}/ipnd/list-types`);
		return ipndListTypes.body;
	}
	async getIpndUsageTypes(destroy$?: Subject<void>): Promise<any> {
		const ipndUsageTypes = await this.http.promise(destroy$).get<any>(`${this.config.apiUrl}${this.config.apiVersion}/ipnd/usage-types`);
		return ipndUsageTypes.body;
	}
	async getIpndServiceTypes(destroy$?: Subject<void>): Promise<any> {
		const ipndServiceTypes = await this.http.promise(destroy$).get<any>(`${this.config.apiUrl}${this.config.apiVersion}/ipnd/service-types`);
		return ipndServiceTypes.body;
	}

	// ======== END OF IPND INTEGRATION ===========

	// ======== KASEYA INTEGRATION ===========

	getKaseyaIntegration(): Observable<KaseyaEntity | boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/kaseya/settings`)
			.pipe(
				map(res => {
					return res.body.data.errorCode ? false : new KaseyaEntity().deserialize(res.body.data);
				}));
	}

	testKaseyaAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/kaseya/settings/test`)
			.pipe(
				map(res => res.status === 200)
				, catchError(er => of(false)));
	}

	public addKaseyaIntegration(settings: KaseyaEntity): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/kaseya/settings`, settings, null);
	}

	public updateKaseyaIntegration(settings: KaseyaEntity): Observable<any> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/kaseya/settings`, null, settings);
	}

	public deleteKaseyaIntegration(): Observable<any> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/kaseya/settings`);
	}

	public forceKaseyaSync(): Observable<HttpResponse<void>> {
		return this.http.post<void>(`${this.config.apiUrl}${this.config.apiVersion}/kaseya/synchronization/force`, null, null);
	}

	// ======== END OF KASEYA INTEGRATION ===========

	// ======== HALO INTEGRATION ===========
	async getHaloIntegration(destroy$?: Subject<void>): Promise<HaloEntity> {
		const haloResponse = await this.http.promise(destroy$).get<ApiData<HaloEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/halo/settings`);

		return haloResponse.body?.data ? new HaloEntity().deserialize(haloResponse.body?.data) : new HaloEntity();
	}

	public testHaloAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/halo/settings/test`)
			.pipe(
				map(res => res.status === 200)
				, catchError(er => of(false)));
	}

	public addHaloIntegration(settings: HaloEntity): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/halo/settings`, settings, null);
	}

	public updateHaloIntegration(settings: HaloEntity): Observable<any> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/halo/settings`, null, settings);
	}

	public deleteHaloIntegration(): Observable<any> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/halo/settings`);
	}
	public forceHaloSync(): Observable<HttpResponse<void>> {
		return this.http.post<void>(`${this.config.apiUrl}${this.config.apiVersion}/halo/synchronization/force`, null, null);
	}

	// ======== END OF HALO INTEGRATION ===========

	// ======== PINCH INTEGRATION ===========

	getPinchIntegration(): Observable<PinchEntity | boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/pinch`)
			.pipe(
				map(res => {
					return res.body.data.errorCode ? false : new PinchEntity().deserialize(res.body.data);
				}));
	}

	testPinchAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/pinch/test`)
			.pipe(
				map(res => res.status === 200)
				, catchError(() => of(false)));
	}

	public addPinchIntegration(settings: PinchEntity): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/pinch`, settings, null);
	}

	public updatePinchIntegration(settings: PinchEntity): Observable<any> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/pinch`, null, settings);
	}

	async deletePinchIntegration(destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/pinch`);
		return res.body;
	}

	// ======== END OF PINCH INTEGRATION ===========

	// ======== NEXIO INTEGRATION ===========

	async getNexioIntegration(destroy$?: Subject<void>): Promise<NexioEntity> {
		const nexioResponse = await this.http.promise(destroy$).get<ApiData<NexioEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/nexio`);

		return nexioResponse.body?.data ? new NexioEntity().deserialize(nexioResponse.body?.data) : new NexioEntity();
	}

	testNexioAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/nexio/test`)
			.pipe(
				map(res => res.status === 200)
				, catchError(() => of(false)));
	}

	public addNexioIntegration(settings: NexioEntity): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/nexio`, settings, null);
	}

	public updateNexioIntegration(settings: NexioEntity): Observable<any> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/nexio`, null, settings);
	}

	async deleteNexioIntegration(destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/nexio`);
		return res.body;
	}

	// ======== END OF NEXIO INTEGRATION ===========

	// ======== CERETAX INTEGRATION ===========
	async getCeretaxIntegration(destroy$?: Subject<void>): Promise<CereTaxEntity> {
		const res = await this.http.promise(destroy$).get<ApiData<CereTaxEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/ceretax-settings`);
		return res.body?.data ? new CereTaxEntity().deserialize(res.body?.data) : new CereTaxEntity();
	}

	async testCeretax(destroy$?: Subject<void>): Promise<boolean>{
		const res = await this.http.promise(destroy$).get<ApiData<CereTaxEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/ceretax-settings/test`);
		return res.status === 200;
	}

	async addCeretaxIntegration(settings: CereTaxEntity, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/ceretax-settings`, settings);
		await this.integrationSettingsRepository.updateIntegrationSettings();
		return res.body;
	}

	async updateCeretaxIntegration(settings: CereTaxEntity, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/ceretax-settings`, settings);
		return res.body;
	}

	async deleteCeretaxIntegration(destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/ceretax-settings`);
		await this.integrationSettingsRepository.updateIntegrationSettings();
		return res.body;
	}

	async refreshCeretaxPScodes(destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).get(`${this.config.apiUrl}${this.config.apiVersion}/ceretax-settings/ps-codes/refresh`);
		this.cache.removeKey('suretax-codes');
		return res.body;
	}

	async getCeretaxTaxExemptions(destroy$?: Subject<void>): Promise<CeretaxTaxExemption[]> {
		const res = await this.http.promise(destroy$).get<ApiData<CeretaxTaxExemption[]>>(`${this.config.apiUrl}${this.config.apiVersion}/ceretax-settings/tax-exemptions`);

		return res.body?.data;
	}
	async getCeretaxTaxExemptionsById(destroy$: Subject<void>, id: Guid): Promise<CeretaxTaxExemption | null> {
		const exemptions = await this.getCeretaxTaxExemptions(destroy$);
		const exemption = exemptions.find(exemption => exemption.id.equals(id));

		if (!exemption) {
			throw new Error(`Exemption not found`);
		}

		return exemption;
	}

	async addCerataxTaxExemption(instance: CeretaxTaxExemption, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/ceretax-settings/tax-exemptions`, instance);
		return res.body;
	}

	async updateCerataxTaxExemption(instance: CeretaxTaxExemption, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/ceretax-settings/tax-exemptions/${instance.id}`, instance);
		return res.body;
	}

	async deleteCerataxTaxExemption(id: Guid, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/ceretax-settings/tax-exemptions/${id}`);
		return res.body;
	}

	async getCerataxLookups(destroy$?: Subject<void>): Promise<CeretaxDataLookups> {
		const res = await this.http.promise(destroy$).get<ApiData<CeretaxDataLookups>>(`${this.config.apiUrl}${this.config.apiVersion}/ceretax-settings/data-lookups`);
		return res.body?.data;
	}

	// ======== END OF CERETAX INTEGRATION ===========

	// ======== CENSUS INTEGRATION ===========
	getCensusIntegration(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/integrations/censusgeo`)
			.pipe(
				map(res => !(res.body.data?.errorCode))
				, catchError(() => of(false)));
	}

	addCensusIntegration(): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/integrations/censusgeo`, null);
	}

	public deleteCensusIntegration(): Observable<any> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/integrations/censusgeo`);
	}

	// ======== CENSUS INTEGRATION ===========

	// ======== MONERIS INTEGRATION ===========

	getMonerisIntegration(): Observable<MonerisEntity | boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/moneris`)
			.pipe(
				map(res => {
					return res.body.data.errorCode ? false : new MonerisEntity().deserialize(res.body.data);
				}));
	}

	testMonerisAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/moneris/test`)
			.pipe(
				map(res => res.status === 200)
				, catchError(() => of(false)));
	}

	public addMonerisIntegration(settings: MonerisEntity): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/moneris`, settings, null);
	}

	public updateMonerisIntegration(settings: MonerisEntity): Observable<any> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/moneris`, null, settings);
	}

	async deleteMonerisIntegration(destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/moneris`);
		return res.body;
	}

	// ======== END OF MONERIS INTEGRATION ===========

	// ======== XERO INTEGRATION ===========

	getXeroIntegration(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/xero/token`)
			.pipe(
				map(res => {
					if (res.body.data.hasToken) {
						return true;
					} else {
						return false;
					}
				}));
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getXeroSyncSettings$(): Observable<XeroEntity> {
		return this.http.get<ApiData<XeroEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/xero/settings`)
			.pipe(
				map(res => {
					return new XeroEntity().deserialize(res.body.data);
				}));
	}

	async getXeroSyncSettings(destroy$?: Subject<void>): Promise<XeroEntity> {
		const res = await this.http.promise(destroy$).get<ApiData<XeroEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/xero/settings`);
		return new XeroEntity().deserialize(res.body.data);
	}

	public disconnectXeroIntegration(): Observable<HttpResponse<any>> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/xero/disconnect`);
	}

	testXeroAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/xero-settings/test`)
			.pipe(
				map(res => {
					return res.status === 200;
				})
				, catchError(() => of(false)));
	}

	public addXeroIntegration(settings: XeroEntity): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/xero-settings`, settings, null);
	}

	public updateXeroIntegration(settings: XeroEntity): Observable<any> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/xero/settings`, null, settings);
	}

	public deleteXeroIntegration(): Observable<any> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/xero-settings`);
	}

	// ======== END OF XERO INTEGRATION ===========

	// ======== STRIPE INTEGRATION ===========

	async getStripeIntegration(destroy$?: Subject<void>): Promise<StripeEntity> {
		const response = await this.http.promise(destroy$).get<ApiData<StripeEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/stripe`);
		return response.body?.data ? new StripeEntity().deserialize(response.body?.data) : new StripeEntity();
	}

	async testStripeAccount(destroy$?: Subject<void>): Promise<boolean>{
		const res = await this.http.promise(destroy$).get<ApiData<CereTaxEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/stripe/test`);
		return res.status === 200 || false;
	}

	async addStripeIntegration(instance: StripeEntity, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/stripe`, instance);
		return res.body;
	}

	async updateStripeIntegration(instance: StripeEntity, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/stripe`, instance);
		return res.body;
	}

	async deleteStripeIntegration(destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/stripe`);
		return res.body;
	}

	// ======== END OF STRIPE INTEGRATION ===========

	// ======== WEBHOOKS INTEGRATION ===========

	getWebHookIntegration(): Observable<WebHookEntity[]> {
		return this.http.get<ApiData<WebHookEntity[]>>(`${this.config.apiUrl}${this.config.apiVersion}/webhooks`)
			.pipe(map(res => res.body.data.map(x => new WebHookEntity().deserialize(x))));

	}

	public addWebHookIntegration(settings: WebHookEntity): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/webhooks`, settings, null);
	}

	public updateWebHookIntegration(settings: WebHookEntity): Observable<any> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/webhooks`, null, settings);
	}

	public deleteWebHookIntegration(id: Guid): Observable<any> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/webhooks/${id}`);
	}

	// ======== END OF WEBHOOKS INTEGRATION ===========

	// ======== QUICKBOOK INTEGRATION ===========

	getQBDInvoices(): Observable<any> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/qbd/invoices`)
			.pipe(
				map(res => {
					return res.body.data;
				})
				, catchError(() => {
					return of([]);
				}));
	}

	public deleteQBDInvoice(id: number): Observable<HttpResponse<void>> {
		return this.deleteEntity(`/qbd/invoices/${id}`);
	}

	getQBDConnectors(): Observable<any> {
		const options = { responseType: 'blob' };

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/qbd/settings/connector`, options)
			.pipe(
				map(res => {
					return res;
				}));
	}

	getQBDIntegration(): Observable<any> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/qbd/settings`)
			.pipe(
				map(res => {
					const arr = [];
					arr.push(new QBDEntity().deserialize(res.body.data));
					return arr;
				})
				, catchError(() => {
					return of(false);
				}));
	}

	addQBDIntegration(settings: QBDEntity): Observable<HttpResponse<void>> {
		return this.http.post<void>(`${this.config.apiUrl}${this.config.apiVersion}/qbd/settings`, settings, null);
	}

	updateQBDIntegration(settings: QBDEntity): Observable<HttpResponse<void>> {
		return this.http.put<void>(`${this.config.apiUrl}${this.config.apiVersion}/qbd/settings`, null, settings);
	}

	public deleteQBDIntegration(): Observable<any> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/qbd/settings`);
	}

	getQuickBooksIntegration(): Observable<any> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks-settings`)
			.pipe(
				map(res => {
					if (res.body.data.errorCode) {
						return false;
					}
					const arr = [];
					arr.push(new QuickBooksEntity().deserialize(res.body.data));
					return arr;
				})
				, catchError(err => of(false)));
	}

	async getQuickBooksDetails(destroy$?: Subject<void>): Promise<QuickBooksEntity> {
		const res = await this.http.promise(destroy$).get<ApiData<QuickBooksEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks-settings/details`);

		return res.body?.data ? new QuickBooksEntity().deserialize(res.body.data) : new QuickBooksEntity();
	}

	updateQuickBooksDetails(settings: QuickBooksEntity): Observable<HttpResponse<void>> {
		return this.http.post<void>(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks-settings/details`, settings, null);
	}

	QbConnections(): Observable<string> {
		return this.http.get<string>(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks/connect`)
			.pipe(map(res => res.body));
	}

	async getQbExportTypes(destroy$?: Subject<void>): Promise<QuickBookExportType[]> {
		const res = await this.http.promise(destroy$).get<ApiData<QuickBookExportType[]>>(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks/customer-types`);
		return res.body.data.map(x => new QuickBookExportType().deserialize(x));
	}

	async getQbWizardSettings(destroy$: Subject<void>): Promise<string[]> {
		const res = await this.http.promise(destroy$).get<ApiData<{ 'customer-types': { ids: string[]; }; }>>(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks/wizard-settings`);
		return res.body?.data?.['customer-types']?.ids || [];
	}

	testQuickBooksAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks-settings/test`)
			.pipe(
				map(res => {
					return res.status === 200;
				})
				, catchError(er => {
					return of(false);
				}));
	}

	public disconnectQuickBooksIntegration(): Observable<HttpResponse<any>> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks-settings/revoke-token`);
	}

	async getQbTaxCodes(destroy$?: Subject<void>): Promise<QbTaxCode[]> {
		const res = await this.http.promise(destroy$).get<ApiData<QuickBookExportType[]>>(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks/tax-codes`);

		return res.body?.data?.map(x => new QbTaxCode().deserialize(x));
	}

	async getQbPaymentMethods(destroy$?: Subject<void>): Promise<QuickBookPaymentMethod[]> {
		const res = await this.http.promise(destroy$).get<ApiData<QuickBookPaymentMethod[]>>(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks/payment-methods`);

		return res.body?.data;
	}

	getQBDSynclogs(): Observable<any> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/quickbook/synclogs`)
			.pipe(
				map(res => {
					return res.body.data;
				})
				, catchError(() => {
					return of([]);
				}));
	}

	// ======== END OF QUICKBOOK INTEGRATION ===========

	// ======== AVALARA INTEGRATION ===========

	getAvalaraIntegration(): Observable<AvalaraEntity[] | boolean> {
		return this.http.get<ApiData<AvalaraEntity[]>>(`${this.config.apiUrl}${this.config.apiVersion}/avalara-settings`)
			.pipe(
				map(res => {
					const settings = res.body.data.errorCode ? false : res.body.data.map(x => new AvalaraEntity().deserialize(x));
					return settings;
				}));
	}

	testAvalaraAccount(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/avalara-settings/test`)
			.pipe(
				map(res => {
					return res.status === 200;
				})
				, catchError(() => of(false)));
	}

	public addAvalaraIntegration(settings: AvalaraEntity): Observable<HttpResponse<void>> {
		return this.http.post<void>(`${this.config.apiUrl}${this.config.apiVersion}/avalara-settings`, settings, null);
	}

	public updateAvalaraIntegration(settings: AvalaraEntity): Observable<HttpResponse<void>> {
		return this.http.put<void>(`${this.config.apiUrl}${this.config.apiVersion}/avalara-settings`, null, settings)
			.pipe(
				tap(() => this.cache.removeKey('avalara-company')),
			);
	}

	public deleteAvalaraIntegration(): Observable<any> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/avalara-settings`)
			.pipe(
				tap(() => this.cache.removeKey('avalara-company'))
			);
	}

	getAvalaraCompany(): Observable<AvalaraCompany[] | boolean> {
		const key = 'avalara-company';

		if (this.cache.get(key) != null)
			return of(this.cache.get(key));

		if (this.cache.get$(key) != null)
			return this.cache.get$(key);

		const observable =
			this.http.get<ApiData<AvalaraCompany[]>>(`${this.config.apiUrl}${this.config.apiVersion}/avalara/companies`)
				.pipe(map(res => {
					if (res.body.data.errorCode) {
						return false;
					}
					const codes = res.body.data.map(x => new AvalaraCompany().deserialize(x));

					this.cache.set(key, codes);
					this.cache.set$(key, null);

					return codes;
				})
				, catchError(err => of(false)));

		this.cache.set$(key, observable);

		return observable;
	}

	public refreshAvalaraCompany(): Observable<any> {
		const observable =
			this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/avalara/taxcodes/refresh`, null, null);

		return observable;
	}

	// ======== END OF AVALARA INTEGRATION ===========

	// ======== AVALARA COMMUNICATION INTEGRATION ===========

	async getAvalaraCommunicationIntegration(destroy$?: Subject<void>): Promise<AvalaraCommunication> {
		const response = await this.http.promise(destroy$).get<ApiData<AvalaraCommunication>>(`${this.config.apiUrl}${this.config.apiVersion}/avalara-communications-settings`);

		return response.body?.data[0] ? new AvalaraCommunication().deserialize(response.body?.data[0]) : new AvalaraCommunication();
	}

	testAvalaraCommunication(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/avalara-communications-settings/test`)
			.pipe(
				map(res => {
					return res.status === 200;
				})
				, catchError(() => of(false)));
	}

	public addAvalaraCommunicationIntegration(settings: AvalaraCommunication): Observable<HttpResponse<void>> {
		const observable =
			this.http.post<void>(`${this.config.apiUrl}${this.config.apiVersion}/avalara-communications-settings`, settings, null)
				.pipe(
					concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response))))
				);

		return observable;
	}

	public updateAvalaraCommunicationIntegration(settings: AvalaraCommunication): Observable<HttpResponse<void>> {
		return this.http.put<void>(`${this.config.apiUrl}${this.config.apiVersion}/avalara-communications-settings`, null, settings);
	}

	public deleteAvalaraCommunicationIntegration(): Observable<any> {
		const observable = this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/avalara-communications-settings`)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response))))
			);

		return observable;
	}

	public refreshAvalaraCommunicationPairs(): Observable<any> {
		const observable =
			this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/avalara-communications-settings/tspairs/refresh`, null, null);

		return observable;
	}

	public getAvalaraSalesAndUse(): Observable<AvalaraSalesAndUse[]> {

		const key = `avalara-sau`;

		if (this.cacheHasKey(key)) {
			return this.getCache<AvalaraSalesAndUse[]>(key);
		}

		return this.http.get<ApiData<AvalaraSalesAndUse[]>>(`${this.config.apiUrl}${this.config.apiVersion}/avalara-communications-settings/sau-attribute-property`)
			.pipe(
				map(res => {
					const sau = res.body.data.map(x => new AvalaraSalesAndUse().deserialize(x));
					this.setCacheValue(key, sau, null);
					return sau;
				}));
	}

	// ======== END OF AVALARA COMMUNICATION INTEGRATION ===========

	// ======== AVALARA TAX EXEMPTIONS ===========

	getAvalaraTaxExemption(): Observable<AvalaraTaxExemption[]> {
		const key = `avalara-tax-exemption`;
		const condition = this.cache.get('avalara-tax-exemption-needsRefresh');

		if (this.cacheHasKey(key) && condition !== true) {
			return this.getCache<AvalaraTaxExemption[]>(key);
		}

		return this.http.get<ApiData<AvalaraTaxExemption[]>>(`${this.config.apiUrl}${this.config.apiVersion}/avalara-communications/tax-exemptions`)
			.pipe(
				map(res => {
					const taxExemptions = res.body.data.map(x => new AvalaraTaxExemption().deserialize(x));
					this.setCacheValue(key, taxExemptions, null);
					return taxExemptions;
				}),
				catchError(err => of(undefined))
			);

	}

	getAvalaraTaxExemptionById(id: Guid): Observable<AvalaraTaxExemption> {
		const key = `avalara-tax-exemption-${id}`;

		if (this.cacheHasKey(key)) {
			return this.getCache<AvalaraTaxExemption[]>(key)
				.pipe(map(tax => tax.find(x => x.id.equals(id))));
		}

		return this.getAvalaraTaxExemption().pipe(map(tax => tax.find(x => x.id.equals(id))));
	}

	public addAvalaraTaxExemption(instance: AvalaraTaxExemption): Observable<Guid> {
		const key = `avalara-tax-exemption`;

		return this.createEntity(`/avalara-communications/tax-exemptions`, instance)
			.pipe(
				tap(() => this.cache.removeKey(key)),
				map((s) => s.id));
	}

	public updateAvalaraTaxExemption(instance: AvalaraTaxExemption): Observable<void> {
		const key = `avalara-tax-exemption`;

		return this.updateEntity(`/avalara-communications/tax-exemptions/${instance.id}`, instance)
			.pipe(
				tap(s => this.cache.removeKey(key))
				, map(s => null));
	}

	public deleteAvalaraTaxExemption(id: Guid): Observable<void> {
		const key = `avalara-tax-exemption`;

		return this.deleteEntity(`/avalara-communications/tax-exemptions/${id}`)
			.pipe(
				tap(() => this.removeFromCacheCollection(key, id))
				, map(s => null));
	}

	getAvalaraLocation(exemptionId: Guid): Observable<AvalaraLocation[]> {
		const key = `avalara-location-${exemptionId}`;

		if (this.cacheHasKey(key)) {
			return this.getCache<AvalaraLocation[]>(key);
		}

		return this.http.get<ApiData<AvalaraLocation[]>>(`${this.config.apiUrl}${this.config.apiVersion}/avalara-communications/tax-exemptions/${exemptionId}/addresses`)
			.pipe(
				map(res => {
					const location = res.body.data.map(x => new AvalaraLocation().deserialize(x));
					this.setCacheValue(key, location, null);
					return location;
				}));
	}

	public addAvalaraLocation(exemptionId: Guid, instance: AvalaraLocation): Observable<Guid> {
		const key = `avalara-location-${exemptionId}`;

		return this.createEntity(`/avalara-communications/tax-exemptions/${exemptionId}/addresses`, instance)
			.pipe(
				tap(tax => this.addCacheCollectionItem(key, tax))
				, map(s => s.id));
	}
	public updateAvalaraLocation(exemptionId: Guid, instance: AvalaraLocation): Observable<void> {
		const key = `avalara-location-${exemptionId}`;

		return this.updateEntity(`/avalara-communications/tax-exemptions/${exemptionId}/addresses`, instance)
			.pipe(
				tap(s => this.updateCacheCollectionItem(key, instance.id, instance))
				, map(s => null));
	}

	getAvalaraTaxTypes(): Observable<AvalaraTaxTypes[]> {
		const key = `avalara-tax-exemption-types`;

		if (this.cacheHasKey(key)) {
			return this.getCache<AvalaraTaxTypes[]>(key);
		}

		return this.http.get<ApiData<AvalaraTaxTypes[]>>(`${this.config.apiUrl}${this.config.apiVersion}/avalara-communications-settings/tax-types`)
			.pipe(
				map(res => {
					const taxTypes = res.body.data.map(x => new AvalaraTaxTypes().deserialize(x));
					this.setCacheValue(key, taxTypes, null);
					return taxTypes;
				}));
	}

	// ======== END OF AVALARA TAX EXEMPTIONS===========

	// ======== QUOTER INTEGRATION ===========

	async getQuoterIntegration(destroy$?: Subject<void>): Promise<QuoterEntity> {
		const res = await this.http.promise(destroy$).get<ApiData<QuoterEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/quoter-setting`);
		return hasApiError(res.body.data) ? new QuoterEntity() : new QuoterEntity().deserialize(res.body.data);
	}

	async getQuoteDetails(uuid: string, destroy$?: Subject<void>): Promise<QuoteDetails> {
		const res = await this.http.promise(destroy$).get<ApiData<QuoteDetails>>(`${this.config.apiUrl}${this.config.apiVersion}/quoter/quotes/${uuid}`);
		return hasApiError(res.body.data) ? new QuoteDetails() : new QuoteDetails().deserialize(res.body.data);
	}

	public addQuoterIntegration(url: string): Observable<any> {
		const objUrl = {
			urlDomain: url
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/quoter-setting`, objUrl)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response)))),
				map(res => res.body.data?.errorCode ? false : new QuoterEntity().deserialize(res.body.data)));
	}


	public updateQuouterIntegration(payload: {}): Observable<HttpResponse<void>> {
		return this.http.put<void>(`${this.config.apiUrl}${this.config.apiVersion}/quoter-setting`, null, payload);
	}

	async updateQuoterIntegrationAsync(payload: {}, destroy$?: Subject<void>): Promise<void> {
		await this.http.promise(destroy$).put<void>(`${this.config.apiUrl}${this.config.apiVersion}/quoter-setting`, payload);
	}

	public deleteQuoterIntegration(): Observable<HttpResponse<void>> {
		return this.deleteEntity(`/quoter-setting`)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response))))
			);
	}

	public getQuotes(): Observable<QuoteEntity[]> {
		return this.http.get<ApiData<QuoteEntity[]>>(`${this.config.apiUrl}${this.config.apiVersion}/quoter/quotes`)
			.pipe(map(res => res.body.data.map(x => new QuoteEntity().deserialize(x))), catchError(() => of([])));
	}

	async previewQuote(agreement: AgreementEntity, uuid: string, destroy$?: Subject<void>): Promise<QuotePreviewItem[]> {
		const acceptObj = this.getPreviewObject(agreement);

		const res = await this.http.promise(destroy$).post<ApiData<QuotePreviewItem[]>>(`${this.config.apiUrl}${this.config.apiVersion}/quoter/quotes/${uuid}/preview`, acceptObj);

		return res.body?.data.map(x => new QuotePreviewItem().deserialize(x));
	}

	private getPreviewObject(agreement: AgreementEntity): object {
		return {
			agreementName: agreement.agreementName,
			billingFrequencyTypeId: agreement.billingFrequencyTypeId,
			isDefault: agreement.isDefault,
			productsStartDate: agreement.productsStartDate,
			billingStartDate: agreement.billingStartDate,
			dateEnd: agreement.dateEnd,
			dateStart: agreement.dateStart,
			site: agreement.site,
			siteId: agreement.siteId,
			customerId: agreement.customerId,
			agreementId: agreement.id,
			paymentTermId: agreement.paymentTermId,
			paymentTermLabel: agreement.paymentTermLabel,
			productsSiteId: agreement.productsSiteId,
			productsSiteZId: agreement.productsSiteZId,
			selectedProductSiteNew: agreement.selectedProductSiteNew,
			selectedProductSiteZNew: agreement.selectedProductSiteZNew,
			selectedAgreementSiteNew: agreement.selectedAgreementSiteNew,
			newSites: agreement.newSites,
		};
	}

	async previewItQuote(agreement: AgreementEntity, uuid: string, destroy$?: Subject<void>): Promise<QuotePreviewItem[]> {
		const acceptObj = this.getPreviewObject(agreement);

		const res = await this.http.promise(destroy$).post<ApiData<QuotePreviewItem[]>>(`${this.config.apiUrl}${this.config.apiVersion}/it-quoter/quotes/${uuid}/preview`, acceptObj);

		return res.body?.data.map(x => new QuotePreviewItem().deserialize(x));
	}

	public acceptQuote(agreement: AgreementEntity, uuid: string, isForce: boolean, useProductDescriptionFromQuote: boolean): Observable<any> {
		const acceptObj = {
			agreementName: agreement.agreementName,
			billingFrequencyTypeId: agreement.billingFrequencyTypeId,
			isDefault: agreement.isDefault,
			productsStartDate: agreement.productsStartDate,
			billingStartDate: agreement.billingStartDate,
			dateEnd: agreement.dateEnd,
			dateStart: agreement.dateStart,
			site: agreement.site,
			siteId: agreement.siteId,
			customerId: agreement.customerId,
			agreementId: agreement.id,
			paymentTermId: agreement.paymentTermId,
			paymentTermLabel: agreement.paymentTermLabel,
			productsSiteId: agreement.productsSiteId,
			productsSiteZId: agreement.productsSiteZId,
			selectedProductSiteNew: agreement.selectedProductSiteNew,
			selectedProductSiteZNew: agreement.selectedProductSiteZNew,
			selectedAgreementSiteNew: agreement.selectedAgreementSiteNew,
			newSites: agreement.newSites,
			useProductDescriptionFromQuote: useProductDescriptionFromQuote,
			invoiceOneOffItems: agreement.invoiceOneOffItems,
			invoiceRecurringItem: agreement.invoiceRecurringItem,
			ignoreCodes: agreement.ignoreCodes,

			force: isForce
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/quoter/quotes/${uuid}/accept`, acceptObj, null)
			.pipe(
				map(res => {
					if (agreement.customerId) {
						this.cache.set('agreements-' + agreement.customerId + '-needsRefresh', true);
					} else if (agreement.id === null && agreement.customerId === null) {
						this.cache.removeKey('active-customers');
					}

					return res;
				}));
	}

	public cancelQuote(uuid: string): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/quoter/quotes/${uuid}/cancel`, null, null)
			.pipe(catchError(er => {
				return throwError(() => er);
			}));
	}

	// ======== END OF QUOTER INTEGRATION ===========

	// ======== ITQUOTER  INTEGRATION ===========

	testItQuoter(): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/it-quoter/test`)
			.pipe(
				map(res => {
					return res.status === 200;
				})
				, catchError(() => of(false)));
	}

	async getItQuoterIntegration(destroy$?: Subject<void>): Promise<ItQuoterEntity> {
		const res = await this.http.promise(destroy$).get<ApiData<ItQuoterEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/it-quoter-setting`);
		return hasApiError(res.body.data) ? new ItQuoterEntity() : new ItQuoterEntity().deserialize(res.body.data);
	}

	async getItQuoteDetails(uuid: string, destroy$?: Subject<void>): Promise<QuoteDetails> {
		const res = await this.http.promise(destroy$).get<ApiData<QuoteDetails>>(`${this.config.apiUrl}${this.config.apiVersion}/it-quoter/quotes/${uuid}`);
		return hasApiError(res.body.data) ? new QuoteDetails() : new QuoteDetails().deserialize(res.body.data);
	}

	public addItQuoterIntegration(payload: {}): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/it-quoter-setting`, payload, null)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response)))),
				map(res => res.body.data.errorCode ? false : new ItQuoterEntity().deserialize(res.body.data))
			);
	}

	public updateItQuouterIntegration(payload: {}): Observable<HttpResponse<void>> {
		return this.http.put<void>(`${this.config.apiUrl}${this.config.apiVersion}/it-quoter-setting`, null, payload);
	}

	async updateItQuouterIntegrationAsync(payload: {}, destroy$?: Subject<void>): Promise<void> {
		await this.http.promise(destroy$).put<void>(`${this.config.apiUrl}${this.config.apiVersion}/it-quoter-setting`, payload);
	}

	public deleteItQuoterIntegration(): Observable<HttpResponse<void>> {
		return this.deleteEntity(`/it-quoter-setting`)
			.pipe(
				concatMap(response => this.integrationSettingsRepository.updateIntegrationSettings$().pipe(concatMap(() => of(response))))
			);
	}

	public getItQuotes(): Observable<QuoteEntity[]> {
		return this.http.get<ApiData<QuoteEntity[]>>(`${this.config.apiUrl}${this.config.apiVersion}/it-quoter/quotes`)
			.pipe(map(res => res.body.data.map(x => new QuoteEntity().deserialize(x))));
	}

	public acceptItQuote(agreement: AgreementEntity, uuid: string, isForce: boolean, useProductDescriptionFromQuote: boolean): Observable<any> {
		const acceptObj = {
			agreementName: agreement.agreementName,
			billingFrequencyTypeId: agreement.billingFrequencyTypeId,
			isDefault: agreement.isDefault,
			productsStartDate: agreement.productsStartDate,
			billingStartDate: agreement.billingStartDate,
			dateEnd: agreement.dateEnd,
			dateStart: agreement.dateStart,
			site: agreement.site,
			siteId: agreement.siteId,
			customerId: agreement.customerId,
			agreementId: agreement.id,
			paymentTermId: agreement.paymentTermId,
			paymentTermLabel: agreement.paymentTermLabel,
			productsSiteId: agreement.productsSiteId,
			productsSiteZId: agreement.productsSiteZId,
			selectedProductSiteNew: agreement.selectedProductSiteNew,
			selectedProductSiteZNew: agreement.selectedProductSiteZNew,
			selectedAgreementSiteNew: agreement.selectedAgreementSiteNew,
			newSites: agreement.newSites,
			useProductDescriptionFromQuote: useProductDescriptionFromQuote,
			invoiceOneOffItems: agreement.invoiceOneOffItems,
			invoiceRecurringItem: agreement.invoiceRecurringItem,
			ignoreCodes: agreement.ignoreCodes,

			force: isForce
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/it-quoter/quotes/${uuid}/accept`, acceptObj, null)
			.pipe(
				map(res => {
					if (agreement.customerId) {
						this.cache.set('agreements-' + agreement.customerId + '-needsRefresh', true);
					} else if (agreement.id === null && agreement.customerId === null) {
						this.cache.removeKey('active-customers');
					}

					return res;
				}));
	}

	public cancelItQuote(uuid: string): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/it-quoter/quotes/${uuid}/cancel`, null, null)
			.pipe(catchError(er => {
				return throwError(() => er);
			}));
	}

	// ======== END OF ITQUOTER INTEGRATION ===========

	// ======== IPPAY COMMUNICATION INTEGRATION ===========

	async getIppayIntegration(destroy$?: Subject<void>): Promise<IppayEntity> {
		const res = await this.http.promise(destroy$).get<ApiData<IppayEntity>>(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/ippay`);
		return hasApiError(res.body.data) ? new IppayEntity() : new IppayEntity().deserialize(res.body.data);
	}

	public addIppayIntegration(settings: IppayEntity): Observable<HttpResponse<void>> {
		return this.http.post<void>(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/ippay`, settings, null);
	}

	async deleteIppayIntegration(destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/ippay`);
		return res.body;
	}

	public updateIppayIntegration(settings: IppayEntity): Observable<HttpResponse<void>> {
		return this.http.put<void>(`${this.config.apiUrl}${this.config.apiVersion}/payment-settings/ippay`, null, settings);
	}

	// ======== END OF IPPAY INTEGRATION ===========
}
